<template>

  <div id="gange">

    <video-background :src="require(`@/assets/gangerisitas.mp4`)" :poster="require(`@/assets/back.png`)" style="height: 100vh;">
      <div id="hud">
        <h1><u>LeGange.org</u></h1>
        <i>Nous te rendons grâce.</i>
        <br /><br />
        <h2>₿ ATH</h2>
        <span>{{ath}} USD</span>

<br /><br />
        <h2>Prix actuel</h2>
        <span>

          <number
    ref="price"
	:from="ath_value"
	:to="price"
	:format="formatCurrency"
	:duration="8"
  easing="Expo.easeOut"
  /> USD</span>

          <br /><br />


        <h2>Taux de purification</h2>

        <span><number
  ref="intensity"
:from="0"
:to="intensity"
:format="formatPercentage"
:duration="8"
easing="Expo.easeOut"
/>%</span>





<br /><br />
        <!--  or  -->
  <vue-svg-gauge
    :value="intensity"
    :separator-step="25"
    :min="0"
    :max="100"
    gauge-color="green"
    :scale-interval="10"
  />

  <hr />

  <a href="https://etherscan.io/address/0xe3D45541f24D0dFf22CFA7917149Cb9396D9D1C7" target="_blank">Une offrande au Gange?</a>

      </div>

    </video-background>
  </div>

</template>

<script>

  import Pizzicato from 'pizzicato'



  import formatMoney from 'accounting-js/lib/formatMoney.js';

  export default {
    name: 'Gange',
    data: function() {
      return {
        ath_value: 68907,
        price: null,
        animation_duration:0
      }
    },
    computed: {
      intensity: function() {
        return (1-(this.price/this.ath_value))*100
      },
      ath: function() {
        return formatMoney(this.ath_value, { symbol: "$", precision: 0 })
      }
    },
    methods: {
      updatePrice: function() {
        this.axios.get('https://min-api.cryptocompare.com/data/pricemulti?fsyms=BTC&tsyms=USD').then(response => {
          this.price = response.data.BTC.USD

        })
      },
      formatCurrency(value) {
  return formatMoney(value, { symbol: "$", precision: 0 })
},
formatPercentage(value) {
  return value.toFixed(2)
}
    },

    mounted: function() {
      this.updatePrice()
      setInterval(
        function() {
          this.updatePrice()
        }.bind(this),
        10000
      )


      // Play
      var music = new Pizzicato.Sound(require('@/assets/gange.mp3'), function() {

//         var ringModulator = new Pizzicato.Effects.RingModulator({
//     speed: 30,
//     distortion: 1,
//     mix: 0.5
// });
//
// music.addEffect(ringModulator);
        // Sound loaded!
        music.play()
      })


    }
  }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

  body {
    margin: 0px;
    font-family: 'Fraunces', serif;
  }

  #content {
    padding: 50px;
  }

  #hud {

    background-color: white;
    width:270px;
    margin:20px;
    padding:20px;
    opacity:0.9;
    text-align:center;
    -webkit-border-radius: 5px;
-moz-border-radius: 5px;
border-radius: 5px;
  }

#hud a {
  color: black;
  font-size:12px;
}

h1 {
  font-size:30px;
  padding:0;
  margin:0;
}

h2 {
  font-size:20px;
  padding:0;
  margin:2;
}
</style>
