<template>
  <div id="app">
    <Gange />
  </div>
</template>

<script>


import Gange from './components/Gange.vue'

export default {
  name: 'App',
  components: {
    Gange
  }
}
</script>

<style>

</style>
