import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false


// Video Background
import VideoBackground from 'vue-responsive-video-background-player'
Vue.component('video-background', VideoBackground);

// Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// Gauge
import VueSvgGauge from 'vue-svg-gauge'
Vue.use(VueSvgGauge)

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)


new Vue({
  render: h => h(App),
}).$mount('#app')
